import Grid from "@mui/material/Grid";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {me} from "../actions/Profile";
import {LeftMenu} from "../components/LeftMenu";
import {StyleAppBar} from "../components/StyleAppBar";
import StyleTypography from "../components/StyledComponents/StyleTypography";
import Loading from "../components/Loading";
import {useNavigate} from "react-router-dom";
import {Chip} from "@mui/material";
import {getPayScheduleRuns} from "../actions/PayScheduleRun";
import StyleTable from "../components/StyledComponents/StyleTable";
import Button from "@mui/material/Button";
import {WarningBox} from "../components/Warning";
import {findEmployer} from "../actions/Employer";
import Link from "@mui/material/Link";
import {formatDateFromBackendWithTime} from "../utils/Helpers";
import {FailedReportNotification} from "../components/Pages/MainPage/FailedReportNotification";
import {TaxYearBanner} from "../components/Pages/MainPage/TaxYearBanner";

function Greeting() {
	const currentHour = new Date().getHours();
	let greeting;

	if (currentHour < 12) {
		greeting = 'Good morning';
	} else if (currentHour < 18) {
		greeting = 'Good afternoon';
	} else {
		greeting = 'Good evening';
	}

	return greeting;
}

function renderPayRunStatusColor(status) {
	switch (status) {
		case "Submitted":
			return {
				backgroundColor: "#0360FD",
				color: "#FFFFFF"
			};
		default:
			return {
				backgroundColor: "#C4C4C4",
				color: "#FFFFFF"
			};
	}
}

function disableLeftMenu(employer) {
	 return !!(
		 employer?.rti_key?.hmrc_connected === false ||
		 employer?.account_locked ||
		 (employer?.basic_setup_step === 2 && employer?.basic_setup_complete)
	 );

}


/**
 * @returns {JSX.Element}
 * @constructor
 */
const MainPage = ({
	me = () => {},
	getPayScheduleRuns = () => {},
	user = {
		id: null,
		temporary_password: false,
	},
	pay_schedule_runs = [],
	pay_schedule_run_loading = false,
	user_loading = false,
	findEmployer = () => {},
	employer = {},
	employer_loading,
	fps_resubmissions = [],
}): JSX.Element => {

	const push = useNavigate();
	const columns = [
		{
			name: "pay_schedule.name",
			label: "Schedule Name",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			},
		},
		{
			name: "tax_period",
			label: "Tax Period",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			}
		},
		{
			name: "pay_run_period",
			label: "Pay Run",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			}
		},
		{
			name: "period_end_date",
			label: "Pay Date",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{formatDateFromBackendWithTime(value)}
					</StyleTypography>
				),
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				filter: true,
				sort: false,
				setCellProps: () => ({className: "font-size"}),
				customBodyRender: (value) => (
					<Chip label={value} className={"mui-chip"} sx={renderPayRunStatusColor(value)} />
				)
			}
		},
		{
			name: "id",
			label: " ",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) =>
					<Grid container alignItems={"center"}>
						<Button
							sx={{display: pay_schedule_runs.filter((item) => item.id === value)[0].active ? 'flex' : 'none'}}
							onClick={() => {
								push(`/main/run-payroll/${btoa(value.toString())}`);
							}}
							disabled={
								disableLeftMenu(employer)
							}
							className={"run-payroll-button"}
						>
							<StyleTypography
								fontSize={16}
								fontSizeMedium={11}
								color={"#FFFFFF"}
								fontWeight={'bold'}
							>
								Run Payroll
							</StyleTypography>
						</Button>
					</Grid>
			}
		},
	];

	useEffect(() => {
		me();
		findEmployer(["rti_key","active_pay_schedule"]);
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [findEmployer, me]);

	useEffect(() => {
		getPayScheduleRuns({
			"pay_schedule_runs": {
				page: 'main',
			}
		});
	}, [getPayScheduleRuns]);

	useEffect(() => {

		if (user.temporary_password) {
			push("/main/settings/edit-password");
		}
	}, [user.temporary_password, push]);

	if (pay_schedule_run_loading || user_loading || employer_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"main-page"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
				employer={employer}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Home"}
						lockMenuItems={[
							employer?.rti_key?.hmrc_connected === false ? 'Payroll' : null,
							employer?.rti_key?.hmrc_connected === false ? 'People' : null,
							employer?.rti_key?.hmrc_connected === false ? 'Reports' : null,
							employer?.rti_key?.hmrc_connected === false ? 'Company' : null,
							employer?.rti_key?.hmrc_connected === false ? 'Auto Enrolment' : null,
						]}
						lockAllMenuItems={disableLeftMenu(employer)}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>

						{/*Greetings User*/}
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							justifyContent={"space-between"}
							alignItems={"center"}
							marginBottom={2}
						>
							<Grid>
								<StyleTypography
									fontSize={40}
									fontSizeMedium={27}
									fontWeight={"bold"}
								>
									{Greeting()}, {user.name.split(" ")[0]}
								</StyleTypography>
							</Grid>
						</Grid>

						{/*FPS Failed Notification*/}
						{
							fps_resubmissions.map((run) => (
								<FailedReportNotification
									payScheduleRun={run}
								/>
							))
						}

						{/*Close Tax Year*/}
						{
							employer?.show_close_year && (
								<TaxYearBanner push={push}/>
							)
						}

						{/*No PaySchedules*/}
						{
							employer?.active_pay_schedule === null  && (
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									className={"team-member-table-container"}
									sx={{
										paddingTop: '20px'
									}}
								>
									<WarningBox
										text={'You currently have no active pay schedule.'}
										link={
											<Link
												href={'/main/company/pay-schedules'}
												color={'#0360FD'}
											>
												Click here to setup a new pay schedule
											</Link>
										}
									/>
								</Grid>
							)
						}

						{/*Account Locked*/}
						{
							employer?.account_locked && (
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									className={"team-member-table-container"}
									sx={{
										paddingTop: '20px'
									}}
								>
									<WarningBox
										text={'Your account has been locked. Please contact support.'}
									/>
								</Grid>
							)
						}

						{/*Waiting for direct debit*/}
						{
							employer?.basic_setup_step === 2 && employer?.basic_setup_complete && (
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									className={"team-member-table-container"}
									sx={{
										paddingTop: '20px'
									}}
								>
									<WarningBox
										text={'You need to finish setting up your direct debit before running payroll.'}
									/>
								</Grid>
							)
						}

						{
							!employer?.rti_key?.hmrc_connected ? (
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									className={"team-member-table-container"}
									sx={{
										paddingTop: '20px'
									}}
								>
									<WarningBox
										text={'You need to validate your HMRC credentials, before running payroll.'}
										link={
											<Link
												href={'/main/hmrc-settings'}
												color={'#0360FD'}
											>
												Click here
											</Link>
										}
									/>
								</Grid>
							):(
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									className={"team-member-table-container"}
									sx={{
										paddingTop: '50px'
									}}
								>
									<StyleTable
										columns={columns}
										data={pay_schedule_runs ?? []}
										options={{
											selectableRowsHeader: false,
											selectableRows: "none",
											responsive: "simple",
											enableNestedDataAccess: ".",
											selectToolbarPlacement: "none",
											searchAlwaysOpen: false,
											print: false,
											download: false,
											search: false,
											viewColumns: false,
											elevation: 0,
											filter: false,
											searchProps: {
												placeholder: "Search pay runs",
												classes: {
													input: "default-text-field"
												}
											},
										}}
										removeToolbar={true}
										removeFooter={true}
									/>
								</Grid>
							)
						}

					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		PayScheduleRun,
		Employer
	} = state;

	return {
		...Profile,
		...PayScheduleRun,
		...Employer,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	getPayScheduleRuns: (data) => dispatch(getPayScheduleRuns(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage)
