import Grid from "@mui/material/Grid";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import StyleTypography from "../../StyledComponents/StyleTypography";
import StyleButton from "../../StyledComponents/StyleButton";
import React from "react";
import { format, isDate } from "date-fns";

export const PayScheduleConfirmDialog = ({
	open = false,
	schedule = {},
	setOpen = () => {},
	addPaySchedule = () => {},
	isOnboarding,
	push,
}): JSX.Element => {
	return (
		<Dialog
			onClose={() => setOpen(false)}
			open={open}
			fullWidth={true}
			maxWidth={'sm'}
			id={'salary-modal'}
		>
			<DialogTitle>
				<StyleTypography
					fontSize={35}
					fontSizeMedium={28}
					fontWeight={'bold'}
				>
					Confirm Your Payment Schedule
				</StyleTypography>
			</DialogTitle>
			<DialogContent>
				<Grid
					item
					container
				>
					<Grid container>
						<StyleTypography
							fontSize={22}
							fontSizeMedium={18}
							color={"black"}
							fontWeight={"bold"}
						>
							Your first pay run will be on:
						</StyleTypography>
					</Grid>
					<Grid container paddingLeft={"20px"} paddingTop={"10px"} paddingBottom={"10px"}>
						<Grid container>
							<StyleTypography
								fontSize={20}
								fontSizeMedium={16}
								color={"black"}
							>
								{"Tax Period: "}{schedule?.taxPeriod}
							</StyleTypography>
						</Grid>
						<Grid container>
							<StyleTypography
								fontSize={20}
								fontSizeMedium={16}
								color={"black"}
							>
								{"Payment Date: "}{isDate(schedule?.firstPayDate) ? format(schedule?.firstPayDate, "dd/MM/yyyy") : ""}
							</StyleTypography>
						</Grid>
					</Grid>
					<StyleTypography
						fontSize={13}
						fontSizeMedium={10}
						color={"black"}
					>
						Note: If you want your first pay run to start earlier, please click on cancel and change your first payment date.
					</StyleTypography>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<StyleButton
							isWhiteButton={true}
							onClick={() => setOpen(false)}
						>
							<StyleTypography
								fontSize={16}
								fontSizeMedium={12}
								color={'black'}
							>
								Cancel
							</StyleTypography>
						</StyleButton>
					</Grid>
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<StyleButton
							onClick={() =>
								addPaySchedule({
									"pay_schedules": {
										name: schedule?.scheduleName,
										frequency_code: schedule?.payFrequency,
										week_payday: (schedule?.payFrequency === "W1" || schedule?.payFrequency === "W2" || schedule?.payFrequency === "W4") ? schedule?.payDays : null,
										month_payday: ((schedule?.payFrequency === "M1") && (schedule?.payDays !== 'Last Day of Month')) ? schedule?.payDays : null,
										custom_payday: (schedule?.payFrequency === "M1") && (schedule?.payDays === 'Last Day of Month') ? schedule?.payDays : null,
										starting_period_end_date: isDate(schedule?.firstPayDate) ? format(schedule?.firstPayDate, "yyyy-MM-dd") : "",
										day_rate_method: schedule?.dayRateMethod,
										automatic_pay_schedule: schedule?.automaticPaySchedule,
										require_client_authorisation: schedule?.clientAuthorisation,
										close_upon_approval: schedule?.closePayRun,
										authoriser_name: schedule?.authoriserName,
										authoriser_email: schedule?.authoriserEmail,
									},}, isOnboarding, push)
								}
						>
							<StyleTypography
								fontSize={16}
								fontSizeMedium={12}
								color={'#FFF'}
							>
								Save
							</StyleTypography>
						</StyleButton>
					</Grid>

				</Grid>
			</DialogActions>
		</Dialog>
	)
}