import Grid from "@mui/material/Grid";
import React from "react";
import Divider from "@mui/material/Divider";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import 'react-calendar/dist/Calendar.css';
import StyleButton from "../../../StyledComponents/StyleButton";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {format, isDate} from "date-fns";
import {useNavigate} from "react-router-dom";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayScheduleBureauClientAutomatiClosePayRun = ({
	addPaySchedule = () => { },
	schedule = {},
	setSchedule = () => {},
	isOnboarding,
}): JSX.Element => {
	const push = useNavigate();
	return (
		<Grid
			container
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<Grid>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={27}
						fontWeight={"bold"}
					>
						Create a new pay schedule
					</StyleTypography>
				</Grid>
			</Grid>
			<Grid
				paddingTop={"30px"}
				paddingBottom={"50px"}
				width={"100%"}
			>
				<Divider />
			</Grid>

			{/*Automatically Close Pay Run*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				direction={"row"}
				alignContent={"flex-start"}
				spacing={4}
			>
				<Grid
					xl={6}
					lg={6}
					md={6.5}
					sm={6.5}
					xs={6.5}
					item
				>
					<Grid>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={15}
							color={'black'}
							fontWeight={"bold"}
						>
							Automatically close pay run upon client authorisation
						</StyleTypography>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={"#98989A"}
						>
							Once the client authorises the pay run, it can be automatically closed
						</StyleTypography>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={"#98989A"}
						>
							and finalised without
							further action needed from you. This ensures
						</StyleTypography>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={"#98989A"}
						>
							a seamless and efficient payroll process.
						</StyleTypography>
					</Grid>
					<Grid marginTop={"1vw"}>
						<FormControlLabel
							id={"pay-schedule-switch"}
							control={
								<Switch
									sx={{ marginLeft: 'auto' }}
									checked={schedule?.closePayRun}
									onClick={() => {
										schedule.closePayRun = !schedule.closePayRun;
										setSchedule(schedule);
									}}
								/>
							}
							label={
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={"black"}
									fontWeight={"bold"}
								>
									I will close the pay run
								</StyleTypography>
							}
							sx={{marginLeft: 0}}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid
				container
				direction={"row"}
				marginTop={"50px"}
				alignItems={"center"}
				id={"pay-schedule-automatically-close-note"}
			>
				<Grid>
					<StyleTypography
						fontSize={16}
						fontSizeMedium={13}
						color={"black"}
						fontWeight={"bold"}
					>
						Note:
					</StyleTypography>
				</Grid>
				<Grid marginLeft={"5px"}>
					<StyleTypography
						fontSize={16}
						fontSizeMedium={13}
						color={"black"}
					>
						When enabled, the pay run will be completed immediately after client authorisation,
						and payroll will be processed accordingly.
					</StyleTypography>
				</Grid>
			</Grid>
			<Grid
				marginTop={"2.5vw"}
				container
			>
				<StyleButton
					onClick={() =>
						addPaySchedule({
							"pay_schedules": {
								name: schedule?.scheduleName,
								frequency_code: schedule?.payFrequency,
								week_payday: (schedule?.payFrequency === "W1" || schedule?.payFrequency === "W2" || schedule?.payFrequency === "W4") ? schedule?.payDays : null,
								month_payday: ((schedule?.payFrequency === "M1") && (schedule?.payDays !== 'Last Day of Month')) ? schedule?.payDays : null,
								custom_payday: (schedule?.payFrequency === "M1") && (schedule?.payDays === 'Last Day of Month') ? schedule?.payDays : null,
								starting_period_end_date: isDate(schedule?.firstPayDate) ? format(schedule?.firstPayDate, "yyyy-MM-dd") : "",
								day_rate_method: schedule?.dayRateMethod,
								automatic_pay_schedule: schedule?.automaticPaySchedule,
								require_client_authorisation: schedule?.clientAuthorisation,
								close_upon_approval: schedule?.closePayRun,
								authoriser_name: schedule?.authoriserName,
								authoriser_email: schedule?.authoriserEmail,
							},
						}, isOnboarding, push)
					}
					customStyle={{height: "3.5vw", width: "180px"}}
				>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"white"}
						fontWeight={"bold"}
					>
						Save
					</StyleTypography>
				</StyleButton>
			</Grid>
		</Grid>
	);
}

export default PayScheduleBureauClientAutomatiClosePayRun;