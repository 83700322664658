import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import 'react-calendar/dist/Calendar.css';
import StyleButton from "../../../StyledComponents/StyleButton";
import WhiteAndBlueWarning from "../../../../images/svg/white-and-blue-warning.svg";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayScheduleBureauClientConfirmation = ({
	setStep = () => {},
	schedule = {},
	setSchedule = () => {},
}): JSX.Element => {
	const [warningDialog, setWarningDialog] = useState(true);

	return (
		<Grid
			container
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<Grid>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={27}
						fontWeight={"bold"}
					>
						Create a new pay schedule
					</StyleTypography>
				</Grid>
			</Grid>
			<Grid
				paddingTop={"30px"}
				paddingBottom={"50px"}
				width={"100%"}
			>
				<Divider />
			</Grid>

			{/*Pay Schedule Information*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				direction={"row"}
				alignContent={"flex-start"}
				spacing={4}
			>
				<Grid
					xl={4.5}
					lg={4.5}
					md={6.5}
					sm={6.5}
					xs={6.5}
					item
				>
					<Grid>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={15}
							color={'black'}
							fontWeight={"bold"}
						>
							Send pay run for client confirmation
						</StyleTypography>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={"#98989A"}
						>
							You have the option of sending prepared pay run to the client for their review and confirmation.
							This allows the client to verify all employee details and payment amounts before payroll is finalised.
						</StyleTypography>
					</Grid>
					<Grid marginTop={"1vw"}>
						<FormControlLabel
							id={"pay-schedule-switch"}
							control={
								<Switch
									sx={{ marginLeft: 'auto' }}
									checked={schedule?.clientAuthorisation}
									onClick={() => {
										schedule.clientAuthorisation = !schedule.clientAuthorisation;
										setSchedule(schedule);
									}}
								/>
							}
							label={
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={"black"}
									fontWeight={"bold"}
								>
									Client authorisation not required
								</StyleTypography>
							}
							sx={{marginLeft: 0}}
						/>
					</Grid>
				</Grid>
			</Grid>
			{
				schedule?.clientAuthorisation &&
				<Grid
					marginTop={"2.5vw"}
					container
					alignContent={"flex-start"}
				>
					<Grid marginBottom={"10px"}>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={"black"}
							fontWeight={"bold"}
						>
							Please add the client details below
						</StyleTypography>
					</Grid>

					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						container
						direction={"row"}
						alignContent={"flex-start"}
						spacing={4}
					>
						{/*Name*/}
						<Grid
							xl={4.5}
							lg={4.5}
							md={6.5}
							sm={6.5}
							xs={6.5}
							item
						>
							<InputLabel
								className={"default-input-label"}
							>
								Name
							</InputLabel>
							<TextField
								className={"default-text-field"}
								value={schedule.authoriserName}
								placeholder={"Type authorised name"}
								onChange={(e) => {
									schedule.authoriserName = e.target.value;
									setSchedule(schedule);
									if (!schedule.clientAuthorisation) {
										schedule.authoriserName = "";
										schedule.authoriserEmail = "";
										setSchedule(schedule);
									}
								}}
								fullWidth
								inputProps={{
									className: "bold-input"
								}}
							/>
						</Grid>

						{/*Email*/}
						<Grid
							xl={4.5}
							lg={4.5}
							md={6.5}
							sm={6.5}
							xs={6.5}
							item
						>
							<InputLabel
								className={"default-input-label"}
							>
								Email
							</InputLabel>
							<TextField
								className={"default-text-field"}
								value={schedule.authoriserEmail}
								placeholder={"Type authorised email"}
								onChange={(e) => {
									schedule.authoriserEmail = e.target.value;
									setSchedule(schedule);
								}}
								fullWidth
								inputProps={{
									className: "bold-input"
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			}
			<Grid
				width={"180px"}
				marginTop={"2.5vw"}
			>
				<StyleButton
					onClick={() => setStep(3)}
					customStyle={{height: "3.5vw"}}
					disabled={(schedule?.clientAuthorisation && (schedule.authoriserName.length === 0 ||
						schedule.authoriserEmail.length === 0))}
				>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"white"}
						fontWeight={"bold"}
					>
						Continue
					</StyleTypography>
				</StyleButton>
			</Grid>

			<Dialog
				open={warningDialog}
				id={"pay-schedule-bureau-modal"}
				fullWidth={true}
				maxWidth={'sm'}
			>
				<DialogTitle>
					<Grid
						container
						direction={"row"}
						justifyContent={"center"}
					>
						<Grid
							item
						>
							<img
								src={WhiteAndBlueWarning}
								className={"pay-schedule-bureau-modal-warning-img"}
								alt={"white-and-blue-warning"}
							/>
						</Grid>
						<Grid item>
							<StyleTypography
								fontSize={35}
								fontSizeMedium={28}
								fontWeight={'bold'}
							>
								Warning
							</StyleTypography>
						</Grid>

					</Grid>
				</DialogTitle>
				<DialogContent>
					<Grid
						container
						textAlign={"center"}
					>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={15}
							color={'black'}
						>
							Enabling automatic pay scheduling means that on the selected pay date,
							FreePayroll will automatically process payroll and salaries according to the schedule
							without requiring further confirmation. Additionally, the Full Payment Submission (FPS)
							will be automatically submitted to HMRC. Please ensure all employee details and payment
							amounts are accurate before activating this feature.
						</StyleTypography>
					</Grid>

				</DialogContent>
				<DialogActions>
					<StyleButton
						onClick={() => setWarningDialog(false)}
						customStyle={{height: "3.5vw"}}
					>
						<StyleTypography
							fontSize={16}
							fontSizeMedium={12}
							color={"white"}
							fontWeight={"bold"}
						>
							I understand
						</StyleTypography>
					</StyleButton>
					<StyleButton
						isWhiteButton={true}
						onClick={() => setStep(1)}
						customStyle={{height: "3.5vw"}}
					>
						<StyleTypography
							fontSize={16}
							fontSizeMedium={12}
							color={"#000"}
							fontWeight={"bold"}
						>
							Cancel
						</StyleTypography>
					</StyleButton>
				</DialogActions>
			</Dialog>
		</Grid>
	);
}

export default PayScheduleBureauClientConfirmation;